import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { TopLeft, TopRight, BottomLeft, BottomRight } from './BackgroundTriangle'

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`
const Content = styled.div`
  width: 90%;
  max-width: 1400px;
  padding: 3em 0;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: minmax(200px, auto);
  align-items: center;
`

const Section = ({ triangle, children }) => {
  let Triangle = null
  switch (triangle) {
    case 'top-right':
      Triangle = <TopRight />
      break
    case 'top-left':
      Triangle = <TopLeft />
      break
    case 'bottom-right':
      Triangle = <BottomRight />
      break
    case 'bottom-left':
      Triangle = <BottomLeft />
      break
    default:
      Triangle = null
      break
  }

  return (
    <Container>
      <Content>
        {Triangle}
        {children}
      </Content>
    </Container>
  )
}

Section.propTypes = {
  triangle: PropTypes.oneOf(['top-right', 'top-left', 'bottom-right', 'bottom-left']),
}

export default Section
