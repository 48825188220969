import React, { useEffect } from 'react'
import styled from 'styled-components'
import { TimelineMax, Linear } from 'gsap'

const Container = styled.span`
  display: inline-block;
  i:nth-child(1) {
    background-color: ${props => props.color || props.theme.primary};
  }
  i:nth-child(2) {
    background-color: ${props => props.color || props.theme.medium};
  }
  i:nth-child(3) {
    background-color: ${props => props.color || props.theme.highlight};
  }
`

const Circle = styled.i`
  display: inline-block;
  margin-right: calc(3px + (5 - 3) * (100vw - 325px) / (2000 - 325));
  border-radius: 50%;
  width: calc(10px + (13 - 10) * (100vw - 400px) / (2000 - 325));
  height: calc(10px + (13 - 10) * (100vw - 400px) / (2000 - 325));
`

const Ellipisis = ({ color }) => {
  const tl = new TimelineMax()
  useEffect(() => {
    tl.staggerFrom('.circle', 1, { yoyo: true, repeat: -1, scale: 0.75, y: -5, ease: Linear.easeInOut }, 0.3)
  }, [tl])

  return (
    <Container color={color}>
      <Circle className="circle" />
      <Circle className="circle" />
      <Circle className="circle" />
    </Container>
  )
}

export default Ellipisis
