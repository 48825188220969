import styled from 'styled-components'

const Triangle = styled.div`
  position: absolute;
  z-index: -1000;
  height: 100%;
  width: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.8), rgba(180, 180, 180, 0));
  @media screen and (min-width: 1000px) {
    width: 80%;
  }
`
export const BottomRight = styled(Triangle)`
  top: 0;
  right: 0;
  clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
  background: linear-gradient(45deg, rgba(255, 255, 255, 1), rgba(180, 180, 180, 0.3));
`
export const BottomLeft = styled(Triangle)`
  top: 0;
  left: 0;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.8), rgba(180, 180, 180, 0.3));
`
export const TopRight = styled(Triangle)`
  top: 0;
  right: 0;
  clip-path: polygon(100% 0%, 0% 0%, 100% 100%);
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.8), rgba(180, 180, 180, 0.3));
`
export const TopLeft = styled(Triangle)`
  top: 0;
  left: 0;
  clip-path: polygon(0% 0%, 0% 100%, 100% 0%);
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.8), rgba(180, 180, 180, 0.3));
`
// const BackgroundTriangle = ({ position }) => {
//   switch (position) {
//     case 'top-right':
//       return <TopRight />
//     case 'top-left':
//       return <TopLeft />
//     case 'bottom-left':
//       return <BottomLeft />
//     case 'bottom-right':
//       return <BottomRight />
//     default:
//       return ''
//   }
// }

// BackgroundTriangle.propTypes = {
//   position: PropTypes.oneOf(['top-right', 'top-left', 'bottom-right', 'bottom-left']),
// }

// export default BackgroundTriangle
