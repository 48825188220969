import React from 'react'
import styled from 'styled-components'
import { DefaultLayout } from '../components/Layout'
import { Ellipsis } from '../components/Typography'
import { CallBanner } from '../components/Banners'

const Heading = styled.div`
  margin: 2em 0;
  p {
    margin-top: 2em;
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: auto;
  div {
    background-color: ${props => props.theme.white};
    padding: 4em 2em;
    margin: 0 auto;
    border-radius: 50px;
  }
  @media screen and (min-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5em;
    padding: 50px;
    div:nth-child(1) {
      grid-column: 1 /3;
    }
    div:nth-child(2) {
      grid-column: 3 /5;
    }
    div:nth-child(3) {
      grid-column: 1 /5;
    }
    div:nth-child(4) {
      grid-column: 1 /5;
    }
  }
`

const ServicePage = () => (
  <DefaultLayout title="Services Milaco">
    <Heading>
      <h1>Services</h1>
      <h3>Find out more about or services</h3>
      <p>
        {' '}
        Our aim is to build strong relationships with clients through cost effective compliance and dependable
        mobilisation coupled with accurate and reliable results. With our extensive knowledge and experience we can help
        clients comply with the DSEAR Regulations in a cost effective way and safeguard site safety. Below is more
        information about our core services.
      </p>
    </Heading>
    <Content>
      <div>
        <h5>Area Classification</h5>
        <p>
          What DSEAR Regulations require: Every employer shall classify places at the workplace where an explosive
          atmosphere may occur into hazardous or non-hazardous areas which are then classified into hazardous zones.
          Area classification is about assessing the likelihood that explosive atmospheres will occur, their type and
          persistence and then the extension of the hazardous zone. Area classifications are another crucial element of
          the explosion risk assessment. The obligations on the plant operator in the DSEAR Regulation is to identify,
          utilising the area classification places where controls over ignition sources are required Milaco engineers
          are skilled in classifying Hazardous environments where explosive atmospheres may occur. We will work
          seamlessly with your engineers and production staff. Producing an area classification document which will
          become the basis for your future installation and compliance.
        </p>
      </div>
      <div>
        <h5>Atex Inspections</h5>
        <p>
          Taken From DSEAR Regulations: employers must ensure that verification of their hazardous area equipment is
          carried out by someone competent. The Inspector must have obtained sufficient practical and theoretical
          knowledge from prior experience and/or professional training relevant to the particular workplace and work
          activity they intend to inspect. BS EN (IEC) 60079-17 outlines the details for ongoing ATEX inspection of
          equipment used or associated with hazardous areas, whether it be gas, vapour or dusts Milaco will ensure your
          organisations' compliance with DSEAR. Compliance is achieved by yearly Inspection over a three-year term
          utilising three different grades of ATEX inspection. Visual, Close, and Detailed, each of which provides an
          increased degree of investigation. Our engineers will draw on their vast experience and knowledge of all
          current and previous standards. Working in conjunction with your engineers, we will create a suitable,
          cost-effective inspection program using modern web-based technologies.
        </p>
      </div>
      <div>
        <h5>Initial Inspection</h5>
        <p>
          On the completion of the installation work and prior to your first use, Milaco will complete initial detailed
          inspection of the new installation which will be carried out in accordance with Annex C, which is based on the
          “detailed” grade of inspection in IEC 60079-17. Initial inspections are now regarded as part of the
          installation process. In 2013 they were moved from the inspection standard (IEC 60079-17) to the installation
          standard (IEC 60079-14). Milaco will be checking during the Initial inspections that the selected type of
          protection and its installation are appropriate. After which verification dossier shall be prepared for every
          installation and as required should be either kept on the premises or stored in another location. In the
          latter case, a document shall be left on the premises indicating who the owner or owners are and where that
          information is kept, so that when required, copies may be obtained.
        </p>
      </div>
      <div>
        <h5>BS 7671 Electrical Testing</h5>
        <p>
          The Electricity at Work Regulations 1989, recommends that all electrical works in the UK conform to IET Wiring
          Regulations, known as BS 7671. The regulations cover the design, selection, erection, inspection and testing
          of electrical installations. The Electrical Installation Certificate provides written confirmation that the
          installation work to which it relates to has been designed, constructed, inspected and tested in accordance
          with BS 7671 These requirements may seem overly complex and hard to comprehend a clear set of actions you need
          to undertake. Therefore we will advise you on your statutory responsibilities. Our service will ensure your
          existing installation complies with these regulations. We can implement a testing schedule and strategy to
          ensure your fixed wiring remains compliant at all times. Compliance is vital not just for safety but also BS
          7671 compliance is required by Insurance Companies and local HSE departments. Under the Health and Safety at
          Work Act 1974, which incorporates the Management of Health and Safety at Work Regulations 1992, a statutory
          legal duty is placed on all Employers. Employers must ensure employees and all others are not subjected to
          risks to their health and safety in the workplace In particular, the Electricity at Work Regulations 1989
          requires those in control of part or all of an electrical system to ensure the system is safe to use and is
          maintained throughout its working life. Milaco engineers are fully qualified to Test Electrical Installations
          in hazardous areas. We are well placed to advise and apply your electrical testing requirements to offer you
          complete peace of mind
        </p>
      </div>
    </Content>
  </DefaultLayout>
)

export default ServicePage
